















































































import {} from "element-ui";
import { Component, Prop, Ref, Vue, Watch, Emit } from "vue-property-decorator";
import { Concept } from "@/models";
import { translations } from '@/mixins';
import Translations from '../../../Translations.vue';
import { required, email } from "vee-validate/dist/rules";
import {mapGetters} from "vuex";

interface OpeningHoursDataObject {
  open: string;
  day: string;
  close: string;
}

const ConceptTimesProps = Vue.extend({
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
});

@Component({
  components: {
    Translations,
  },
  computed: {
    ...mapGetters({
      getLocale: 'app/getLocale'
    })
  },
  mixins: [translations],
})
export default class ConceptTimes extends ConceptTimesProps {
  translate!: Function;
  public getLocale: any;

  mounted() {
    console.log('getLocale: ', this.getLocale);
  }
}
